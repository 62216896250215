import React from "react";
import HotList from "../utils/components/HotList";
import { BooleanField, TextField } from "react-admin";
import { addressFilter } from "./components/AddressFilter";

const AddressList = ( props ) => (
  <HotList sort={"sortierungsnummer"} filters={addressFilter} allowCreate={true} {...props}>
      <TextField source={"originId"} label={"id"}/>
      <TextField source={"firma"} label={"Firma"}/>
      <TextField source={"suchname"}/>
      <TextField source={"fullName"} label={"Name"} sortable={false}/>
      <TextField source={"ort"} label={"Ort"} sortBy={"ort"}/>
      <TextField source={"strasse"} label={"Straße"} sortBy={"strasse"}/>
      <TextField source={"tel"}/>
      <TextField source={"email"}/>
        <BooleanField source={"stellplatz"} label={"Stellplatz"} sortable={false}/>
  </HotList>
);

export default AddressList;
