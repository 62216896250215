import translateCustomerLabel from "../../contracts/components/TranslateCustomerLabel";
import { AutocompleteInput, AutocompleteArrayInput } from "react-admin";
import ReferenceInput from "../../utils/components/ReferenceInput";
import ReferenceArrayInput from "../../utils/components/ReferenceArrayInput";
import React from "react";

const CustomerReferenceInput = React.memo(({emptyLabel='Kein Kunde', ...props}) => {
  const { filter } = props

  let filterValues = {
    boolchar_cleared: false,
    aktiv: 1,
    ...filter
  }

  return (
    <ReferenceInput
      label="Auftraggeber"
      reference="customers"
      filter={filterValues}
      sort={{ field: "firma", order: "ASC" }}
      helperText={false}
      filterToQuery={( searchText ) => ( { _name: searchText } )}
      {...props}
    >
      <AutocompleteInput
        style={{ minWidth: "200px" }}
        optionText={( customer ) => {
          return translateCustomerLabel( customer, emptyLabel )
        }}
        helperText={false}
      />
    </ReferenceInput>
  );
});

const CustomerReferenceArrayInput = (props) => {
  const { filter } = props

  let filterValues = {
    boolchar_cleared: false,
    aktiv: 1,
    ...filter
  }

  return (
    <ReferenceArrayInput
      reference={"customers"}
      sort={{ field: "firma", order: "ASC" }}
      filter={filterValues}
      filterToQuery={( searchText ) => ( { _name: searchText } )}
      {...props}
      allowEmpty={false}
    >
      <AutocompleteArrayInput
        optionText={( customer ) => translateCustomerLabel( customer )}
        style={{ minWidth: "200px" }}
      />
    </ReferenceArrayInput>
  );
};

export { CustomerReferenceInput, CustomerReferenceArrayInput };
