import { Box, Typography, withStyles } from "@material-ui/core";
import { ArrayInput, BooleanInput, DateInput, DateTimeInput, FormDataConsumer, FormTab, maxLength, SelectArrayInput, SelectInput } from "react-admin";
import { InputContainer, LeftBox, ParagraphBox, RightBox, Spacer } from "../../../utils/components/FormElements";
import NumberInput from "../../../utils/components/NumberInput";
import { ResetEmptyRunButton, ResetLockButton } from "../Buttons/ResetButton";
import TimeRangeInput from "../../../utils/components/TimeRangeInput";
import { validateTime } from "../../../utils/validators/DefaultValidators";
import * as React from "react";
import { TextInput } from "../../../utils/components";
import { ServiceReferenceArrayInput } from "../../../services/components/ServiceReferenceInput";
import DefaultStyledFormIterator from "../../../utils/components/CustomIterator";
import SimpleFormIndexableIterator from "../../../utils/components/SimpleFormIndexableIterator/SimpleFormIndexableIterator";

const styles = {
  root: {
    // backgroundColor: '#FAFAFA',
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, 50%)",
    margin: 0
    // border: "1px solid grey"
  },

  line: {
    // backgroundColor: '#FAC31E',
    // border: "1px solid red",
    // margin: "5px",
    // padding: "5px",

    // display: "flex",
    // flexDirection: "row",
    // flexWrap: "wrap",
    // alignItems: "baseline"
    display: "grid",
    gridTemplateColumns: "auto",
    gridTemplateRows: "auto",
    alignItems: "center",
    padding: "0.25rem"

  },

  indexContainer: {
    // order: 1

    // backgroundColor: '#C91F1F',
    // border: "1px solid grey",
    // margin: "5px",
    // padding: "5px"

    gridColumnStart: 1,
    gridColumnEnd: 2,
    gridRowStart: 1,
    gridRowEnd: 2,
    padding: 0

  },

  index: {},

  form: {
    // order: 2

    // backgroundColor: '#1F63DA',
    // border: "1px solid grey",
    // margin: "5px",
    // padding: "5px"

    gridColumn: "1 / 3",
    gridRowStart: 2,
    gridRowEnd: 3
  },

  leftIcon: {},

  action: {
    // order: 1

    // backgroundColor: '#8A179A',
    // border: "1px solid grey",
    // margin: "5px",
    // padding: "5px"
    // textAlign: 'right',
    gridColumnStart: 2,
    gridColumnEnd: 3,
    gridRowStart: 1,
    gridRowEnd: 2,
    padding: 0
  }
};

const StyledFormIterator = withStyles( styles )( DefaultStyledFormIterator );

export default (props) => {
  return <FormTab label={"Fahrdaten"} {...props}>
    <Box p="1em" style={{ width: "100%" }}>
      <Box display="flex" mb={"5rem"}>
        <Box flex={1} mr="0.5rem">
          <Typography variant="h6" gutterBottom>
            Fahrzeug
          </Typography>

          <Box display="flex">
            <Box flex={1} mr="0.5em">
              <TextInput
                source="hersteller"
                label={"Hersteller"}
                fullWidth
              />
            </Box>
            <Box flex={1} mr="0.5em">
              <TextInput
                source="autotyp"
                label={"Modell"}
                fullWidth
              />
            </Box>

            <Box flex={1} ml="0.5em">
              <SelectInput
                source={"carType"}
                label={"Fahrzeugtyp"}
                emptyText={"Kein Typ ausgewählt"}
                emptyValue={'INITIAL'}
                allowEmpty={true}
                helperText={false}
                choices={[
                  { id: 'STEILHECKLIMOUSINE', name: 'Steilhecklimousine' },
                  { id: 'LIMOUSINE', name: 'Limousine' },
                  { id: 'KOMBI', name: 'Kombi' },
                  { id: 'TRANSPORTER', name: 'Transporter' },
                  { id: 'KASTENWAGEN', name: 'Kastenwagen' },
                  { id: 'COUPE', name: 'Coupe' },
                ]}
                fullWidth/>
            </Box>
          </Box>

          <Box display={"flex"}>
            <InputContainer left>
              <SelectInput
                source="licensePlateFinCheckResult"
                choices={[
                  { id: 'INVALID', name: 'Kennzeichen / FIN NICHT korrekt' },
                  { id: 'VALID', name: 'Kennzeichen / FIN korrekt' },
                  { id: 'IGNORED', name: 'Wegen Leerfahrt ignoriert' }
                ]}
                label={"Prüfung"}
                emptyText={"Kein Typ ausgewählt"}
                emptyValue={'INITIAL'}
                allowEmpty={true}
                helperText={false}
                allowNull={true}
                fullWidth/>

            </InputContainer>
          </Box>

          <FormDataConsumer>
            {( { formData } ) => {
              let ret

              if( formData.licensePlateFinCheckResult === "INVALID" ) {
                ret =
                  <Box display={"flex"}>
                    <InputContainer left>
                      <TextInput
                        source="licensePlateFinCheckNewPlate"
                        label={"Nummernschild"}
                        fullWidth
                      />
                    </InputContainer>

                    <InputContainer center>
                      <TextInput
                        source="licensePlateFinCheckNewFin"
                        label={"FIN"}
                        validate={maxLength( 17 )}
                        fullWidth
                      />
                    </InputContainer>

                    <InputContainer right>
                      <TextInput
                        multiline
                        source="licensePlateFinCheckReason"
                        label={"Begründung Änderung"}
                        fullWidth
                      />
                    </InputContainer>
                  </Box>


              } else if( formData.licensePlateFinCheckResult === "IGNORED" ) {

              } else if( formData.licensePlateFinCheckResult === "VALID" ) {
                ret = <InputContainer display={"flex"}>
                  <InputContainer left>
                    <TextInput
                      source="licensePlateFinCheckNewPlate"
                      label={"Nummernschild"}
                      fullWidth
                    />
                  </InputContainer>

                  <InputContainer center>
                    <DateTimeInput
                      source="inspectionDate"
                      label={"Tuev f\xe4llig"}
                      allowEmpty={true}
                      fullWidth
                    />
                  </InputContainer>

                  <InputContainer right>
                    <TextInput
                      source="licensePlateFinCheckNewFin"
                      label={"FIN"}
                      validate={maxLength( 17 )}
                      fullWidth
                    />
                  </InputContainer>
                </InputContainer>
              }

              return ret
            }}
          </FormDataConsumer>

          <FormDataConsumer>
            {( { formData } ) => (
              !formData.contract?.angemeldet && <Box display={"flex"}>
                <InputContainer left>

                  <TextInput
                    source="alternativeLicensePlate"
                    label={"Ü-Kennzeichen"}
                    fullWidth
                  />
                </InputContainer>


                <InputContainer right>

                  <TextInput
                    source="driversLogForRedLicensePlatesReferenceListNumber"
                    label={"Seite im Fahrzeugscheinheft"}
                    fullWidth
                  />
                </InputContainer>
              </Box>
            )}
          </FormDataConsumer>

          <Box display="flex" flexDirection={"row"}>
            <BooleanInput
              source={"inspectionDateChanged"}
              helperText={"TÜV Datum ausgewählt"}
              label={"TÜV übernehmen"}
            />
            <BooleanInput
              source={"inspectionDateAccepted"}
              helperText={"Trotz abgelaufenem TÜV akzeptiert"}
              label={"TÜV akzeptiert"}
            />
            <BooleanInput
              source={"angemeldet"}
              label={"Fahrzeug angemeldet"}
            />
            <BooleanInput
              source={"verzoegerteAbgabe"}
              label={"KFZ Vollelektrisch"}
              fullWidth
            />
          </Box>

          <Spacer />

          <ParagraphBox>
            <InputContainer left>
              <DateInput source={"driversLicenseDateOfIssue"} label={"Führerscheindatum"} fullWidth helperText={false}/>
            </InputContainer>
            <InputContainer center>
              <TextInput source={"driversLicenseIssuingAuthority"} label={"Ausstellende Behörde"} fullWidth helperText={false}/>
            </InputContainer>
            <InputContainer center>
              <TextInput source={"receiverSignature.driverLicenseNumber"} label={"Führerschein Nummer"} fullWidth helperText={false}/>
            </InputContainer>
            <InputContainer left ml={"1rem"} alignSelf={"center"}>
              <BooleanInput source={"driversLicenseClassBAllowed"} label={"Klasse B"} fullWidth helperText={false}/>
            </InputContainer>
          </ParagraphBox>
        </Box>

        <Box flex={1} ml="0.5rem">
          <Typography variant="h6" gutterBottom>
            Bereifung
          </Typography>

          <Box display="flex">
            <Box flex={1} mr="0.5em">
              <SelectInput
                source={"tireType"}
                helperText={false}
                label={"Bereifung"}
                choices={[
                  { id: "SOMMER", name: "Sommer" },
                  { id: "WINTER", name: "Winter" },
                  { id: "GANZJAHRES", name: "Ganzjahres" },
                ]}
                fullWidth
              />
            </Box>

            <Box flex={1} mr="0.5em">
              <SelectInput
                source={"rimType"}
                helperText={false}
                label={"Felge"}
                choices={[
                  { id: "STAHL", name: "Stahl" },
                  { id: "ALU", name: "Alu" },
                ]}
                fullWidth
              />
            </Box>
          </Box>

          <Box display="flex">
            <Box flex={1} mr="0.5em">
              <SelectInput
                source={"insideCarTireType"}
                emptyText={"Keine zus\xe4tzliche Bereifung"}
                helperText={false}
                allowEmpty={true}
                label={"Zus\xe4tzliche Bereifung"}
                choices={[
                  { id: "SOMMER", name: "Sommer" },
                  { id: "WINTER", name: "Winter" },
                  { id: "GANZJAHRES", name: "Ganzjahres" },
                ]}
                fullWidth
              />
            </Box>

            <Box flex={1} mr="0.5em">
              <SelectInput
                source={"insideCarRimType"}
                emptyText={"Keine zus\xe4tzliche Felge"}
                helperText={false}
                allowEmpty={true}
                label={"Zus\xe4tzliche Felge"}
                choices={[
                  { id: "STAHL", name: "Stahl" },
                  { id: "ALU", name: "Alu" },
                  { id: "NONE", name: "Ohne" },
                ]}
                fullWidth
              />
            </Box>
          </Box>

          <BooleanInput
            source={"insideCarTiresIncluded"}
            label={"Zus\xe4tzliche Felgen / Reifen vorhanden"}
            helperText={""}/>
        </Box>
      </Box>

      <Box display={"flex"} mb={"2rem"}>
        <Box flex={2} mr="0.5rem">
          <Typography variant="h6" gutterBottom>
            Abholung
          </Typography>
          <Box>
            <Box display="flex" style={{ alignItems: "center" }}>
              <Box flex={1} mr="0.5em">
                <SelectInput label={"Tankstand"}
                             source={"fuelLevelOnSubmit"}
                             choices={[
                               { id: '0', name: "0/8" },
                               { id: '1', name: "1/8" },
                               { id: '2', name: "2/8" },
                               { id: '3', name: "3/8" },
                               { id: '4', name: "4/8" },
                               { id: '5', name: "5/8" },
                               { id: '6', name: "6/8" },
                               { id: '7', name: "7/8" },
                               { id: '8', name: "8/8" }
                             ]}
                             parse={v => parseInt( v )}
                             format={v => v ? v.toString() : null}
                             helperText={false}
                             resettable={true}
                             allowEmpty={true}
                             fullWidth
                />
              </Box>

              <Box flex={1} mr="0.5em">
                <NumberInput
                  label={"KM-Stand Abholung"}
                  source={"mileageOnSubmit"}
                />
              </Box>
              <Box flex={2} mr={"0.5em"}>
                <TextInput
                  label={"Kommentar Fahrer"}
                  source={"driverCollectionComment"}
                  fullWidth
                />
              </Box>
              <Box>
                <BooleanInput source="pickupLocationLocked" label={"Abholadresse sperren"}/>
              </Box>
            </Box>
          </Box>

          <FormDataConsumer>
            {( { formData } ) => (

              formData.pickupLocationLocked && <><Box display={"flex"}>
                <Box flex={2} display={"flex"} alignItems={"center"}>
                  <strong style={{ color: 'red' }}>Änderungen an der Adresse werden nicht mehr mit dem Auftrag synchronisiert.</strong> <br/>
                </Box>
                <Box flex={1}>
                  <ResetLockButton type={"pickupLocationLocked"} record={props.record}/>
                </Box>
              </Box>
                <Spacer/>
              </>
            )}
          </FormDataConsumer>


          <Box>
            <Box display="flex">
              <Box flex={1} mr="0.5em">
                <TextInput
                  source={"pickupLocation.firma"}
                  fullWidth
                  label={"Name / Firma"}
                />
              </Box>
            </Box>

            <Box display="flex">
              <Box flex={1} mr="0.5em">
                <TextInput
                  source={"pickupLocation.strasse"}
                  fullWidth
                  label={"Straße & Hausnummer"}
                />
              </Box>
              <Box flex={1} ml="0.5em">
                <TextInput
                  source={"pickupLocation.ort"}
                  fullWidth
                  label={"Plz & Ort"}
                />
              </Box>
            </Box>

            <Box display={"flex"}>
              <Box flex={1} mr="0.5em">
                <TextInput
                  source={"pickupLocation.ansprechparter1"}
                  fullWidth
                  label={"Ansprechpartner"}
                />
              </Box>
            </Box>

            <Box display={"flex"}>
              <Box flex={1} mr="0.5em">
                <TextInput
                  source={"pickupLocation.anspr1email"}
                  fullWidth
                  label={"E-Mail"}
                />
              </Box>
              <Box flex={1} mr="0.5em">
                <TextInput
                  source={"pickupLocation.anspr1telefon"}
                  fullWidth
                  label={"Telefon"}
                />
              </Box>
            </Box>
          </Box>

          <TextInput
            multiline
            source={"abholinfos"}
            fullWidth
            label={"Infos"}
          />

          <Typography variant="h6" gutterBottom>
            Datum & Uhrzeit Abholung
          </Typography>

          <Box display="flex">
            <DateInput
              source={"pickupDateRaw"}
              fullWidth
              label={"Abholdatum"}
              helperText={false}

            />
          </Box>

          <Box display="flex">
            <Box flex={1} mr="0.5em">
              <TimeRangeInput
                source={"pickupTimeFrom"}
                label={"Von"}
                placeholder={"HH:MM"}
                fullWidth
                helperText={false}
                validate={validateTime}
                step={30}
              />
            </Box>
            <Box flex={1} ml="0.5em">
              <TimeRangeInput
                source={"pickupTimeTo"}
                label={"Bis"}
                placeholder={"HH:MM"}
                fullWidth
                helperText={false}
                validate={validateTime}
                step={30}
              />
            </Box>
          </Box>
        </Box>

        <Box flex={2} ml="0.5rem">
          <Typography variant="h6" gutterBottom>
            Zustellung
          </Typography>

          <Box>
            <Box display="flex" style={{ alignItems: "center" }}>
              <Box flex={1} mr="0.5em">
                <SelectInput label={"Tankstand"}
                             source={"fuelLevelOnReceive"}
                             choices={[
                               { id: 0, name: "0/8" },
                               { id: 1, name: "1/8" },
                               { id: 2, name: "2/8" },
                               { id: 3, name: "3/8" },
                               { id: 4, name: "4/8" },
                               { id: 5, name: "5/8" },
                               { id: 6, name: "6/8" },
                               { id: 7, name: "7/8" },
                               { id: 8, name: "8/8" }
                             ]}
                             resettable={true}
                             helperText={false}
                             allowEmpty={true}
                             fullWidth
                />
              </Box>
              <Box flex={1} mr="0.5em">
                <NumberInput
                  label={"KM-Stand Zustellung"}
                  source={"mileageOnReceive"}
                />
              </Box>
              <Box flex={2} mr={"0.5em"}>
                <TextInput
                  label={"Kommentar Fahrer"}
                  source={"driverDeliveryComment"}
                  fullWidth
                />
              </Box>
              <Box>
                <BooleanInput source="deliveryLocationLocked" label={"Zustelladresse sperren"}/>
              </Box>
            </Box>
          </Box>
          <FormDataConsumer>
            {( { formData } ) => (

              formData.deliveryLocationLocked && <><Box display={"flex"}>
                <Box flex={2} display={"flex"} alignItems={"center"}>
                  <strong style={{ color: 'red' }}>Änderungen an der Adresse werden nicht mehr mit dem Auftrag synchronisiert.</strong> <br/>
                </Box>
                <Box flex={1}>
                  <ResetLockButton type={"pickupLocationLocked"} record={props.record}/>
                </Box>
              </Box>
                <Spacer />
              </>
            )}
          </FormDataConsumer>

          <Box>
            <Box display="flex">
              <Box flex={1} mr="0.5em">
                <TextInput
                  source={"deliveryLocation.firma"}
                  fullWidth
                  label={"Name / Firma"}
                />
              </Box>
            </Box>

            <Box>
              <Box display="flex">
                <Box flex={1} mr="0.5em">
                  <TextInput
                    source={"deliveryLocation.strasse"}
                    fullWidth
                    label={"Straße & Hausnummer"}
                  />
                </Box>
                <Box flex={1} ml="0.5em">
                  <TextInput
                    source={"deliveryLocation.ort"}
                    fullWidth
                    label={"Plz & Ort"}
                  />
                </Box>
              </Box>
            </Box>

            <Box display={"flex"}>
              <Box flex={1} mr="0.5em">
                <TextInput
                  source={"deliveryLocation.ansprechparter1"}
                  fullWidth
                  label={"Ansprechpartner"}
                />
              </Box>
            </Box>

            <Box display={"flex"}>
              <Box flex={1} mr="0.5em">
                <TextInput
                  source={"deliveryLocation.anspr1email"}
                  fullWidth
                  label={"E-Mail"}
                />
              </Box>
              <Box flex={1} mr="0.5em">
                <TextInput
                  source={"deliveryLocation.anspr1telefon"}
                  fullWidth
                  label={"Telefon"}
                />
              </Box>
            </Box>
          </Box>

          <TextInput
            multiline
            fullWidth
            source={"bringinfos"}
            label={"Infos"}
          />

          <Typography variant="h6" gutterBottom>
            Datum & Uhrzeit Zustellung
          </Typography>

          <Box display="flex">
            <Box flex={1} mr="0.5em">
              <DateInput
                source={"deliveryDateRaw"}
                fullWidth
                helperText={false}
                label={"Datum"}
              />
            </Box>
          </Box>

          <Box display="flex">
            <Box flex={1} mr="0.5rem">
              <TimeRangeInput
                source={"deliveryTimeFrom"}
                label={"Von"}
                placeholder={"HH:MM"}
                fullWidth
                helperText={false}
                validate={validateTime}
                step={30}

              />
            </Box>
            <Box flex={1} ml="0.5rem">
              <TimeRangeInput source={"deliveryTimeTo"} label={"Bis"} placeholder={"HH:MM"} fullWidth helperText={false} validate={validateTime} step={30} />
            </Box>
          </Box>
        </Box>
      </Box>
      <Spacer space="5rem"/>

      <Box display={"flex"}>
        <LeftBox>

          <Typography variant={"h6"} gutterBottom>Annahmevorbehalte</Typography>

          <SelectArrayInput
            source={"acceptanceReservations"}
            label={"Annahmevorbehalte"}
            optionText={"title"}
            optionValue={"name"}
            choices={[
              { id: 1, name: "dirt", title: "Verschmutzung" },
              { id: 2, name: "strongDirt", title: "Starke Verschmutzung" },
              { id: 3, name: "wet", title: "Fahrzeug nass" },
              { id: 4, name: "rain", title: "Regen" },
              { id: 5, name: "darkness", title: "Dunkelheit" },
              { id: 6, name: "parkingGarage", title: 'Parkhaus / Tiefgarage' },
              { id: 7, name: "frozen", title: "Fahrzeug vereist" },
              { id: 8, name: "snowIn", title: "Fahrzeug zugeschneit" }
            ]}
            helperText={false}
            fullWidth
          />

          <Spacer space="2rem"/>

          <Typography variant="h6" gutterBottom>Leerfahrt</Typography>
          <Box display={"flex"}>
            <InputContainer left>
              <SelectInput
                source={"emptyRunReason"}
                label={"Begründung"}
                choices={[
                  { id: "contactPersonNotPresent", name: "Ansprechpartner nicht vor Ort" },
                  { id: "notReadyForDriving", name: "Fahrzeug nicht fahrbereit" },
                  { id: "wrongTires", name: "Fahrzeug falsch bereift" },
                  { id: "carInspectionDateExpired", name: "TÜV mehr als 7 Monate fällig" },
                  { id: "storno", name: "Kurzfristig storniert" },
                  { id: "carNotPresent", name: "Fahrzeug nicht vor Ort" },
                  { id: "otherReason", name: "Eigener Grund / Sonstiges" },
                ]}
                fullWidth
                helperText={false}
              />
            </InputContainer>

            <FormDataConsumer>
              {( { formData } ) => {
                return <>
                  <InputContainer right flex={2}>
                    <Box display={"flex"}>
                      {formData.emptyRunReason === "otherReason" && <TextInput source={"emptyRunOtherReason"} label={"Grund"} fullWidth style={{ marginRight: '0.5rem' }}/>}
                      <TextInput source={"emptyRunApprovedBy"} label={"Genehmigt von"} fullWidth/>
                    </Box>
                  </InputContainer>
                </>
              }}
            </FormDataConsumer>

          </Box>

          {props.record?.emptyRunDone && <ResetEmptyRunButton {...props}/>}
        </LeftBox>

        <RightBox mr={"0.5rem"}>
          <Typography variant={"h6"} gutterBottom>Zusatzleistungen</Typography>

          <ServiceReferenceArrayInput source="contract.contract.zusatzleistungenArray" />

          <Spacer space="2rem"/>

          <Typography variant="h6" gutterBottom>Wartezeiten</Typography>

          <ArrayInput source="waitings" label={""} fullWidth style={{margin: 0}}>
            <StyledFormIterator>
              <FormDataConsumer>
                {( { formData, scopedFormData, getSource } ) => {
                  return <>
                    <Box display={"flex"} style={{width: "100%" }}>
                      <Box flex={1} mr={"0.25rem"}>
                        <TextInput source={getSource("approvedBy")} fullWidth label={"Genehmigt von"}/>
                      </Box>
                      <Box flex={1} ml={"0.25rem"}>
                        <NumberInput
                          source={getSource("minutes")}
                          fullWidth
                          label={"Wartezeit in Minuten"}
                        />
                      </Box>
                    </Box>

                    <Box display={"flex"} style={{width: "100%" }}>
                      <Box flex={1} mr={"0.25rem"}>

                      <SelectInput
                        source={getSource("reason")}
                        label={"Begründung"}
                        optionText={"title"}
                        optionValue={"name"}
                        choices={[
                          { name: "notReadyForPickup", title: "Fahrzeug nicht abholbereit" },
                          { name: "carNotPresent", title: "Fahrzeug nicht vor Ort" },
                          { name: "contactPersonNotPresent", title: "Ansprechpartner nicht anwesend" },
                          { name: "equipmentMissing", title: "Ausstattungsdetails fehlten" },
                          { name: "wrongAddress", title: "falsche Anschrift" },
                          { name: "carDamaged", title: "Fahrzeug beschädigt" },
                          { name: "technicalDefect", title: "Technischer Defekt" },
                          { name: "repairShopVisit", title: "Werkstattbesuch" },
                          { name: "refuelingElectroCar", title: "Elektro-Betankung" },
                          { name: "carDirty", title: "Fahrzeug nicht sauber" },
                          { name: "incorrectHouseNumber", title: "Unkorrekte Hausnummer" },
                          { name: "incorrectPhoneNumber", title: "Fehlerhafte Telefonnummer" },
                          { name: "carFrozen", title: "Eingefrorenes Auto" },
                          { name: "carBarricaded", title: "Zugeparktes Auto" },
                          { name: "otherReason", title: "Eigener Grund / Sonstiges" },
                        ]}
                        fullWidth
                        helperText={false}
                      />
                      </Box>

                      <Box flex={1} ml={"0.25rem"}>
                      <SelectInput
                        source={getSource("processState")}
                        label={"Zustand"}
                        fullWidth
                        choices={[
                          { id: "STARTED", name: "Begonnen" },
                          { id: "SUBMITTED", name: "Abgeholt" },
                          { id: "RECEIVED", name: "Ausgeliefert" }
                        ]}
                        defaultValue={"STARTED"}
                        helperText={false}
                      />
                      </Box>

                    </Box>
                  </>
                }}
              </FormDataConsumer>
            </StyledFormIterator>
          </ArrayInput>
        </RightBox>
      </Box>

      <Spacer space="5rem"/>

      <Box display={"flex"}>
        <LeftBox>
          <hr/>
          <BooleanInput source={"editingApproved"} label={"Zur Protokollierung freigegeben"} helperText={false}/>
          <hr/>
          <BooleanInput source={"allUploadsDone"} label={"Alle Bilder hochgeladen"} helperText={false}/>
          <hr/>

          <BooleanInput source={"carControlInnerDone"} helperText={false}/>
          <BooleanInput source={"carControlOuterDone"} helperText={false}/>
          <BooleanInput source={"noInnerDamagesFound"} helperText={false}/>
          <BooleanInput source={"noOuterDamagesFound"} helperText={false}/>
          <BooleanInput source={"licensePlateFinChecked"} helperText={false}/>
          <BooleanInput source={"smokeFound"} helperText={false}/>
        </LeftBox>
        <RightBox>
        </RightBox>
      </Box>
    </Box>
  </FormTab>
}
