import React, { useEffect, useState } from "react";
import {
  ArrayInput,
  BooleanInput, FormDataConsumer
} from "react-admin";
import { useForm } from "react-final-form";
import { Box, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import TextInput from "../../utils/components/TextInput";
import AutocompleteInput from "../../utils/components/AutocompleteInput";
import { getAddressData } from "../../utils/request";
import { validateRequired } from "../../utils/validators/DefaultValidators";
import { AddressReferenceInput, translateAddressLabel } from "../../address/components/AddressReferenceInput";
import { ParagraphBox } from "../../utils/components/FormElements";
import ContactFormIterator from "../../utils/components/InputComponents/ArrayInput/ContactFormIterator";
import TimeRangeInputAlternative from "../../utils/components/TimeRangeInputAlternative";
import 'react-datepicker/dist/react-datepicker.css'
import { unset } from "lodash";
import DatePicker from "../../utils/components/DatePicker";


export const transformIRI = ( v ) => {
  if ( !v ) {
    return null;
  }
  let substr = v.split( "/" );
  if ( substr.length === 4 && parseInt( substr[ substr.length - 1 ] ) > 99999999 ) {
    return null;
  }
  return v;
};
const concatAddressLabel = ( record ) => {
  if ( !record ) {
    return null;
  }

  const { suchname, firma } = record;

  let text = suchname ?? null;
  if ( firma ) {
    text += suchname && firma ? " - " + firma : firma;
  }

  return text === "" ? null : text;
};


const PickUpAddress = props => {
  const { record, isCreate, onFocus, filter } = props;
  const { change, batch, getState } = useForm();
  const [ isBestandsadresse, setIsBestandsadresse ] = useState(
    record.pickupLocationIsSet
  );
  const [ bestandsadresse, setBestandsadresse ] = useState( null );

  useEffect( () => {
    if ( isBestandsadresse ) {
      change( "pickupLocationIsSet", true );
    } else {
      change( "pickupLocationIsSet", false );
    }
  } );

  const handleAddressChange = async ( customerId ) => {
    const newAddress = await getAddressData( customerId );

    if( newAddress.contacts ) {
      // unset field @id in every newAdress.contacts array item
      newAddress.contacts.forEach( ( contact ) => {
        unset( contact, '@id' );
        unset( contact, '@type' );
        unset( contact, 'entityId' );
        unset( contact, 'entity_id' );
        unset( contact, 'entity' );
        unset( contact, 'id' );
        contact.type = 'pickupLocation'
      } );
    }

    if( newAddress.stellplatz ) {
      change('contractType', 'DELIVERY' )
    }

    // Change fields value to new address
    batch( () => {
      change( "pickupLocation.firma", newAddress.firma );
      change("pickupLocation.strasse", newAddress.strasse);
      // change("pickupLocation.nr", newAddress.nr);
      // change("pickupLocation.zip", newAddress.zip);
      change("pickupLocation.ort", newAddress.ort);
      change( "pickupLocation.ansprechparter1", newAddress.ansprechparter1 );
      change( "pickupLocation.anspr1email", newAddress.anspr1email );
      change( "pickupLocation.anspr1telefon", newAddress.anspr1telefon );
      change( "pickupTimeFrom", newAddress.startzeit );
      change( "pickupTimeTo", newAddress.endzeit );
      change( "pickupContacts", newAddress.contacts );
    } );

    setBestandsadresse( newAddress );
    setIsBestandsadresse( true );
    change( "pickupLocation.saveLocation", false )
  };

  const handleTextInputChange = ( prefix ) => {
     const fields = [
       'firma',
       'strasse',
       'ort',
       'ansprechparter1',
       'anspr1telefon',
       'anspr1email',
     ]

    const state = getState()

    if( bestandsadresse ) {
      setIsBestandsadresse( true )
      for( let field of fields ) {
        // const value = formData[prefix][field];
        const value = state.values[prefix][field]
        if( bestandsadresse && bestandsadresse[field] !== value ) {
          setIsBestandsadresse( false )
          break
        }
      }
    } else {
      setIsBestandsadresse( false )
    }
  };


  // if( !record.pickupDate ) {
  //   record.pickupDate = moment().format('DD.MM.YYYY');
  // }

  let parsedPickUpDate = ''

  if( record.pickupDate ) {
    // Split the date string into day, month, and year parts
    const [day, month, year] = record.pickupDate?.split('.');

    // Create a new Date object with the parsed values
    parsedPickUpDate = new Date();
    if (day && month && year) {
      parsedPickUpDate = new Date(`${year}-${month}-${day}`);
    }
  }

  const [pickupDate, setPickupDate] = useState(parsedPickUpDate);


  return (
    <Box>
      <Box display="flex">
        <Box flex={1} mr="0.5em">
          <AddressReferenceInput
            source={"pickupLocation.@id"}
            label={"Bestandskontakt"}
            filter={filter}
            format={( v ) => transformIRI( v )}
            onChange={( address ) => {
              return address ? handleAddressChange( address ) : null;
            }}
            onFocus={onFocus}
          >
            <AutocompleteInput
              optionText={( record ) => translateAddressLabel( record )}
              source={"pickupLocation.firma"}
            />
          </AddressReferenceInput>
        </Box>

        <Box flex={1} mr="0.5em" display={"none"}>
          <BooleanInput
            source={"pickupLocationIsSet"}
            label={"Bestandsadresse"}
          />
        </Box>
        <Box flex={1} ml="0.5em">
          <TextInput
            source={"pickupLocation.firma"}
            fullWidth
            label={"Name / Firma"}
            onChange={(  ) => handleTextInputChange( 'pickupLocation' )}
            validate={isCreate && validateRequired}
          />
        </Box>
      </Box>

      <Box>
        <Box display="flex">
          <Box flex={4} mr="0.25em">
            <TextInput
              source={"pickupLocation.strasse"}
              fullWidth
              label={"Straße"}
              onChange={( event ) => handleTextInputChange( 'pickupLocation' )}
              validate={isCreate && validateRequired}
              onFocus={onFocus}
            />
          </Box>
          <Box flex={4}>
            <TextInput
              source={"pickupLocation.ort"}
              fullWidth
              label={"Ort"}
              onChange={( event ) => handleTextInputChange( 'pickupLocation' )}
              validate={isCreate && validateRequired}
              onFocus={onFocus}
            />
          </Box>
        </Box>

        <Box display={"flex"}>
          <Box flex={1}>
            <TextInput
              source={"pickupLocation.ansprechparter1"}
              fullWidth
              label={"Ansprechpartner"}
              onChange={( event ) => handleTextInputChange( 'pickupLocation' )}
              // validate={isCreate && validateRequired}
            />
          </Box>
        </Box>

        <Box display={"flex"}>
          <Box flex={1} mr="0.5em">
            <TextInput
              source={"pickupLocation.anspr1telefon"}
              fullWidth
              label={"Telefon"}
              onChange={( event ) => handleTextInputChange( 'pickupLocation' )}
              // validate={isCreate && validateRequired}
            />
          </Box>
          <Box flex={1} ml="0.5em" mr={"0.5em"}>
            <TextInput
              source={"pickupLocation.anspr1email"}
              fullWidth
              label={"E-Mail"}
              onChange={( event ) => handleTextInputChange( 'pickupLocation' )}
            />
          </Box>
          <Box flex={1} ml="0.5em">
            <TextInput
              source={"abholemail2"}
              fullWidth
              label={"E-Mail 2"}
            />
          </Box>
        </Box>
        <ParagraphBox>
          <ArrayInput source="pickupContacts" label={""}>
            <ContactFormIterator/>
          </ArrayInput>
        </ParagraphBox>

        <ParagraphBox>
          <BooleanInput source={"pickupLocation.saveLocation"} label={"Adresse für weitere Aufträge speichern"} disabled={isBestandsadresse}/>
        </ParagraphBox>
      </Box>

      <TextInput
        multiline
        source={"abholinfos"}
        fullWidth
        label={"Infos"}
      />

      <Typography variant="h6" gutterBottom>
        Datum & Uhrzeit Abholung
      </Typography>


      <Box display="flex">

        <Box flex={1}>
          <div class="MuiFormControl-root MuiTextField-root MuiFormControl-marginDense MuiFormControl-fullWidth">
            <div style={{ "z-index": "2" }}
                 class="MuiInputBase-root MuiFilledInput-root MuiFilledInput-underline MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-marginDense MuiFilledInput-marginDense">
              <label class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-marginDense MuiInputLabel-filled MuiFormLabel-filled">Abholdatum</label>
              <DatePicker fieldName="pickupDate"/>
            </div>
          </div>
        </Box>
        <Box flex={1} ml="0.5em">
          <TimeRangeInputAlternative record={props.record} sourceFrom={"pickupTimeFrom"} sourceTo={"pickupTimeTo"}/>
        </Box>
      </Box>


      <Box display="flex">
        <Box flex={1} mr="0.5em">
          <BooleanInput
            source={"vorholung"}
            label={"Vorholung"}
            fullWidth
          />
        </Box>
        <Box flex={1} mr="0.5em">
          <BooleanInput
            source={"flexContract"}
            label={"FLEXauftrag"}
          />
        </Box>
      </Box>
    </Box>
  );
};

/**
 *
 * @param record
 * @param isCreate
 * @return {JSX.Element}
 * @constructor
 */
const DeliveryAddress = props => {
  const { record, isCreate, onFocus, filter } = props;
  const { change, batch, getState } = useForm();
  const [ isBestandsadresse, setIsBestandsadresse ] = useState(
    record.deliveryLocationIsSet
  );
  const [ bestandsadresse, setBestandsadresse ] = useState( null );

  useEffect( () => {
    if ( isBestandsadresse ) {
      change( "deliveryLocationIsSet", true );
    } else {
      change( "deliveryLocationIsSet", false );
    }
  });

  const handleAddressChange = async ( customerId ) => {
    const newAddress = await getAddressData( customerId );

    if( newAddress.contacts ) {
      // unset field @id in every newAdress.contacts array item
      newAddress.contacts.forEach( ( contact ) => {
        unset( contact, '@id' );
        unset( contact, '@type' );
        unset( contact, 'entityId' );
        unset( contact, 'entity_id' );
        unset( contact, 'entity' );
        unset( contact, 'id' );
        contact.type = 'deliveryLocation'
      } );
    }

    if( newAddress.stellplatz ) {
      change('contractType', 'PICKUP' )
    }

    // Change fields value to new address
    batch( () => {
      change( "deliveryLocation.firma", newAddress.firma );
      change("deliveryLocation.strasse", newAddress.strasse);
      // change("deliveryLocation.nr", newAddress.nr);
      // change("deliveryLocation.zip", newAddress.zip);
      change("deliveryLocation.ort", newAddress.ort);
      change( "deliveryLocation.ansprechparter1", newAddress.ansprechparter1 );
      change( "deliveryLocation.anspr1email", newAddress.anspr1email );
      change( "deliveryLocation.anspr1telefon", newAddress.anspr1telefon );
      change( "deliveryTimeFrom", newAddress.startzeit );
      change( "deliveryTimeTo", newAddress.endzeit );
      change( "deliveryContacts", newAddress.contacts );
    } );

    setBestandsadresse( newAddress );
    setIsBestandsadresse( true );
    change( "deliveryLocation.saveLocation", false )
  };

  const handleTextInputChange = ( prefix ) => {
    const fields = [
      'firma',
      'strasse',
      'ort',
      'ansprechparter1',
      'anspr1telefon',
      'anspr1email',
    ]

    const state = getState()

    if( bestandsadresse ) {
      setIsBestandsadresse( true )
      for( let field of fields ) {
        // const value = formData[prefix][field];
        const value = state.values[prefix][field] ?? ''
        if( bestandsadresse && bestandsadresse[field] !== value ) {
          setIsBestandsadresse( false )
          break
        }
      }
    } else {
      setIsBestandsadresse( false )
    }
  };



  return (
    <Box>
      <Box display="flex">
        <Box flex={ 1 } mr="0.5em">
          <AddressReferenceInput
            source={ "deliveryLocation.@id" }
            label={ "Bestandskontakt" }
            filter={ filter }
            format={ ( v ) => transformIRI( v ) }
            onChange={ ( address ) => {
              return address ? handleAddressChange( address ) : null;
            } }
            onFocus={ onFocus }
          >
            <AutocompleteInput
              optionText={ ( record ) => translateAddressLabel( record ) }
              source={ "deliveryLocation.firma" }
            />
          </AddressReferenceInput>
        </Box>
        <Box flex={ 1 } ml="0.5em">
          <TextInput
            source={ "deliveryLocation.firma" }
            fullWidth
            label={ "Name / Firma" }
            validate={ isCreate && validateRequired }
            onChange={ ( event ) => handleTextInputChange( 'deliveryLocation' ) }

          />
        </Box>

        <Box flex={ 1 } mr="0.5em" display={ "none" }>
          <BooleanInput
            label={ "Bestandsadresse" }
            source={ "deliveryLocationIsSet" }
            onChange={ ( event ) => handleTextInputChange( 'deliveryLocation' ) }
          />
        </Box>
      </Box>

      <Box>
        <Box>
          <Box display="flex">
            <Box flex={4} mr="0.25em">
              <TextInput
                source={"deliveryLocation.strasse"}
                fullWidth
                label={"Straße"}
                onChange={(event) => handleTextInputChange('deliveryLocation')}
                validate={isCreate && validateRequired}
                onFocus={onFocus}
              />
            </Box>

            <Box flex={4} >
              <TextInput
                source={"deliveryLocation.ort"}
                label={"Ort"}
                fullWidth
                onChange={ ( event ) => handleTextInputChange( 'deliveryLocation' ) }
                validate={isCreate && validateRequired}
                onFocus={ onFocus }
              />
            </Box>
          </Box>

          <Box display={ "flex" }>
            <Box flex={ 1 }>
              <TextInput
                label={ "Ansprechpartner" }
                source={ "deliveryLocation.ansprechparter1" }
                onChange={ ( event ) => handleTextInputChange( 'deliveryLocation' ) }
                fullWidth
              />
            </Box>
          </Box>

          <Box display={ "flex" }>
            <Box flex={ 1 } mr="0.5em">
              <TextInput
                source={ "deliveryLocation.anspr1telefon" }
                onChange={ ( event ) => handleTextInputChange( 'deliveryLocation' ) }
                label={ "Telefon" }
                fullWidth
              />
            </Box>
            <Box flex={ 1 } ml="0.5em" mr={"0.5em"}>
              <TextInput
                source={ "deliveryLocation.anspr1email" }
                onChange={ ( event ) => handleTextInputChange( 'deliveryLocation' ) }
                label={ "E-Mail" }
                fullWidth
              />
            </Box>
            <Box flex={ 1 } ml="0.5em">
              <TextInput
                source={ "bringemail2" }
                fullWidth
                label={ "E-Mail 2" }
              />
            </Box>
          </Box>
          <ParagraphBox>
            <ArrayInput source="deliveryContacts" label={""}>
              <ContactFormIterator />
            </ArrayInput>
          </ParagraphBox>


          <ParagraphBox>
            <BooleanInput
              source={"deliveryLocation.saveLocation"}
              label={"Adresse für weitere Aufträge speichern"}
              disabled={isBestandsadresse} />
          </ParagraphBox>
        </Box>
      </Box>

      <TextInput
        multiline
        fullWidth
        source={"bringinfos"}
        label={"Infos"}
      />

      <Typography variant="h6" gutterBottom>
        Datum & Uhrzeit Zustellung
      </Typography>

      <Box display="flex">
        <Box flex={1}>
          <div class="MuiFormControl-root MuiTextField-root MuiFormControl-marginDense MuiFormControl-fullWidth">
            <div style={{"z-index":"2"}}  class="MuiInputBase-root MuiFilledInput-root MuiFilledInput-underline MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-marginDense MuiFilledInput-marginDense">
              <label class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-marginDense MuiInputLabel-filled MuiFormLabel-filled">Zustelldatum</label>
              <DatePicker fieldName="deliveryDate" />
            </div>
          </div>
        </Box>
        <Box flex={1} ml="0.5rem">
          <TimeRangeInputAlternative record={props.record} sourceFrom={"deliveryTimeFrom"} sourceTo={"deliveryTimeTo"} />
        </Box>
      </Box>
    </Box>
  );
};

DeliveryAddress.propTypes = PickUpAddress.propTypes = {
  record: PropTypes.any,
  isCreate: PropTypes.bool,
};

export { PickUpAddress, DeliveryAddress };
