import { AutocompleteArrayInput, useListContext } from "react-admin";
import ReferenceInput from "../../utils/components/ReferenceInput";
import ReferenceArrayInput from "../../utils/components/ReferenceArrayInput";
import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { getFilter } from "../../contracts/ContractEdit";

const OptionRenderer = choice => {
  const { record, match } = choice
  if( !choice ) {
    return null;
  }

  const classes = useStyles();

  return <span className={classnames( classes.root )}>
    <span className={classnames( classes.id )}>[{record.originId}]</span>
    <span className={classnames( classes.prefix )}>{record.stellplatz ? 'Stp: ' : ''}</span>
    <span className={classnames( classes.label )}>{record.listName ?? 'Keine Adresse'}</span>
  </span>
}

const useStyles = makeStyles( theme => ( {
  root: {},
  id: {
    paddingRight: "5px"
    // width: "45px",
    // display: "inline-block",
    // ...debugStyles( "blue", "0" )
  },
  prefix: {
    // display: "inline-block",

    // ...debugStyles( "green", "0" )
  },
  label: {
    // display: "inline-block",

    // ...debugStyles( "red", "0" )
  }
} ) );

const autocompleteFilter = ( searchText ) => {
  return searchText ? { _name: searchText } : null;
}

const translateAddressLabel = ( address ) => {
  if( !address ) {
    return null;
  }
  let prefix = '', id = '', label = '';

  label = address.listName ?? 'Keine Adresse';
  if( address.stellplatz ) {
    prefix = `Stp: ${label} `;
  }

  if( address.originId ) {
    id = `[${address[ 'originId' ]}] `;
  }
  return id+prefix+label
}

const translateStellplatzLabel = ( stellplatz ) => {
  if( !stellplatz ) {
    return null;
  }
  let id = '', label = '';

  label = stellplatz.firma ?? 'Bitte wählen...';

  if( stellplatz.originId ) {
    id = `[${stellplatz[ 'originId' ]}] `;
  }

  return id+label
}

const AddressReferenceInput = ( { filter, ...props} ) => {

  let filterValues = {
    boolchar_cleared: false,
    ...filter
  }

  return (
    <ReferenceInput
      reference="addresses"
      sort={{ field: "suchname", order: "ASC" }}
      filter={filterValues}
      filterToQuery={autocompleteFilter}
      optionText={translateAddressLabel}
      {...props}
    />
  );
};

const AddressReferenceArrayInputWithSpecialFilter = ( {filter, ...props } ) => {
  const { filterValues } = useListContext();

  const newFilter= {
    ...getFilter( filterValues.auftraggeber, 'allowedCustomers' ),
    ...filter
  }

  return <AddressReferenceArrayInput
    filter={newFilter}
    {...props}
  />
}

const AddressReferenceArrayInput = ( props ) => {
  const { filter, optionText = translateAddressLabel } = props

  let filterValues = {
    boolchar_cleared: false,
    ...filter
  }

  return (
    <ReferenceArrayInput
      label={"Adresse"}
      source={"leistungsadresse"}
      reference={"addresses"}
      sort={{ field: "suchname", order: "ASC" }}
      filterToQuery={autocompleteFilter}

      {...props}
      filter={filterValues}
      allowEmpty={false}
    >
      {props.children ?? (
        <AutocompleteArrayInput
          style={{ minWidth: "200px" }}
          optionText={optionText}
          source={"leistungsadresse.firma"}
          helperText={false}
          matchSuggestion={( filterValue, suggestion ) => {
            const translatedLabel = optionText( suggestion ).toLowerCase()

            return translatedLabel.includes( filterValue?.toLowerCase() ) ||
                   suggestion.listName?.toLowerCase().includes( filterValue?.toLowerCase() )
          }}
        />
      )}
    </ReferenceArrayInput>
  );
};

export { AddressReferenceInput, AddressReferenceArrayInput, AddressReferenceArrayInputWithSpecialFilter, translateAddressLabel, translateStellplatzLabel };
