import React from "react";
import { TextInput, useDelete, useRedirect } from "react-admin";
import { Box, Button, List, ListItem, Toolbar } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/RemoveCircleOutline";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import { makeStyles } from "@material-ui/core/styles";
import { InputContainer, ParagraphBox } from "../../FormElements";

const ContactFormIterator = (props) => {
  return null;

  const total = props.fields?.value?.length ?? 0;
  const [ deleteOne ] = useDelete()


  const addButtonClickHandler = () => {
    props.fields.push({
                        name: '',
                        phone: '',
                        mail: '',
                      });
  }

  const classes = useStyles();

  return (
    <>
      Weitere Ansprechpartner
      <List>
        { props.fields.map( ( item, index ) => {
            return <ListItem key={ index } className={ classes.listItem }>
              <ListItemControl index={ index } total={ total } { ...props } />

              <ParagraphBox style={ { width: '100%' } }>
                <InputContainer left>
                  <TextInput helperText={ false } className={ classes.textInput } label={ "Name" } source={ `${ item }.name` } fullWidth />
                </InputContainer>
                <InputContainer center>
                  <TextInput helperText={ false } className={ classes.textInput } label={ "E-Mail" } source={ `${ item }.mail` } fullWidth />
                </InputContainer>
                <InputContainer right>
                  <TextInput helperText={ false } className={ classes.textInput } label={ "Telefon" } source={ `${ item }.phone` } fullWidth />
                </InputContainer>
              </ParagraphBox>

              <Box display={ 'flex' } flexDirection={ "column" } justifyContent={ "center" } ml={ "0.5rem" }>
                <Button
                  color={ 'primary' }
                  title="Position entfernen"
                  style={ { minWidth: '32px' } }
                  onClick={ () => {
                    props.fields.remove( index )
                    if( props.fields?.value[ index ]['@id'] ) {
                      deleteOne(
                        'contacts',
                        props.fields?.value[ index ][ '@id' ],
                        props.fields?.value[ index ],
                        {
                          onSuccess: () => {
                            notify( `Eintrag erfolgreich entfernt`, { type: 'success' } );
                            setLoading( false )
                          },
                          onFailure: ( error ) => {
                            setLoading( false )
                            notify( error.message || `Fehler beim entfernen`, { type: 'warning', multiLine: true }
                            )
                          }
                        } )
                    }
                  }}>
                  <CloseIcon />
                </Button>
              </Box>
            </ListItem>
        } ) }

        <Toolbar style={ { justifyContent: 'space-between' } }>
          <Button
            color={ 'primary' }
            label="ra.action.add"
            onClick={ addButtonClickHandler }
          >
            <AddIcon />&nbsp;Hinzufügen
          </Button>
        </Toolbar>
      </List>
    </>
  )
}

const ListItemControl = ( props ) => {
  const { index, total } = props;
  const classes = useStyles();
  return <Box className={classes.listItemControl}>

    <Box className={classes.currentPos}>{index+1}</Box>
  </Box>
}

const useStyles = makeStyles( ( theme ) => ( {
  listItem: {
    padding: '0 5px 0 5px',
    display: 'flex',
    backgroundColor: theme.overrides.invoiceTable.row.backgroundColor.default,
    // borderBottom: "2px solid rgba(128,128,128, 0.75)",
    '&:nth-child(odd)': {
      // boxShadow: "rgb(0,0,0,0.26) 0px 2px 20px 0px",
      backgroundColor: theme.overrides.invoiceTable.row.backgroundColor.odd,
    },
    '&:hover': {
      backgroundColor: theme.overrides.invoiceTable.row.backgroundColor.hover,
    },
  },

  listItemControl: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center"
  },

  currentPos: {
    display: "flex",
    alignItems: "center",
  },
  textInput: {
    margin: "8px 3px 4px 3px",
    float: 'left',
  }
} ) )

export default ContactFormIterator;
