import TextInput from "../../../utils/components/TextInput";
import { Box, Card } from "@material-ui/core";
import { ArrayInput, FormDataConsumer, NumberInput, SimpleFormIterator } from "react-admin";
import { ServiceReferenceArrayInput, ServiceReferenceInput } from "../../../services/components/ServiceReferenceInput";
import CustomBooleanInput from "../../../utils/components/CustomBooleanInput";
import AccessoryReferenceInput from "../../../accessories/Components/AccessoryReferenceInput";
import React from "react";
import { ParagraphHeadline } from "../../../utils/components/FormElements";
import { validateRequired } from "../../../utils/validators/DefaultValidators";

export default ( props ) => {
  return <Card className={props.classes.card}>
    <ParagraphHeadline>Fahrzeugdaten</ParagraphHeadline>
    <Box display={"flex"}>
      <Box flex={1} mr="0.5em">
        <TextInput
          source="licensePlate"
          label={"Nummernschild"}
          fullWidth
        />
      </Box>

      <Box flex={1} ml="0.5em">
        <TextInput
          source="carModel"
          label={"Fahrzeugtyp"}
          fullWidth
        />
      </Box>
    </Box>

    <Box>
      <Box display="flex" alignItems={"center"}>
        <Box flex={2} mr="0.5em">
          <ServiceReferenceArrayInput/>
        </Box>

        <Box flex={1} ml="0.5em">
          <CustomBooleanInput source={"waschen"} label={"Waschen"}/>
        </Box>
      </Box>

      <Box display="flex" alignItems={ "center" }>
        <ArrayInput source="serviceAddresses" label={"Zusatzleistungen mit Adresse"} fullWidth>
          <SimpleFormIterator>
            <FormDataConsumer>
              {( { getSource, scopedFormData } ) => {
                return <Box style={{width: "100%"}}>
                  <Box display={"flex"}>
                    <ServiceReferenceInput source={getSource("service")} filter={{boolchar_anschriftnotwendig: true}} helperText={false} validate={validateRequired}/>
                  </Box>
                  <Box display={"flex"}>
                    <Box flex={1} mr={"0.5rem"}>
                      <TextInput source={getSource("contactPerson")} label={"Name"} fullWidth/>
                    </Box>
                    <Box flex={1} mr={"0.5rem"}>
                      <TextInput source={getSource("company")} label={"Firma"} fullWidth/>
                    </Box>
                    <Box flex={1}>
                      <TextInput source={getSource("phone")} label={"Telefonnummer"} fullWidth/>
                    </Box>
                  </Box>
                  <Box display={"flex"}>
                    <Box flex={1} mr={"0.5rem"}>
                      <TextInput source={getSource("street")} label={"Strasse"} fullWidth/>
                    </Box>
                    <Box flex={1} mr={"0.5rem"}>
                      <TextInput source={getSource("houseNumber")} label={"Hausnummer"} fullWidth/>
                    </Box>
                    <Box flex={1} mr={"0.5rem"}>
                      <TextInput source={getSource("zipCode")} label={"PLZ"} fullWidth/>
                    </Box>
                    <Box flex={1}>
                      <TextInput source={getSource("city")} label={"Ort"} fullWidth/>
                    </Box>
                  </Box>
                  <Box>
                    <TextInput source={getSource("info")} label={"Info"} fullWidth/>
                  </Box>

                </Box>
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </Box>

      <AccessoryReferenceInput helperText={false}/>

      <FormDataConsumer>
        {( { formData } ) => (
          <Box display={"flex"}>
            {(formData?.zubehoerArray?.includes( '4' ) || formData?.zubehoerArray?.includes( 4 )) && (
              <Box flex={1} mr={"0.5rem"}><NumberInput source="anzahlSchluessel" label={"Anzahl Schlüssel"} min={0} max={5} fullWidth allowEmpty={true} helperText={false} resettable={true}/></Box>
            )}
            {(formData?.zubehoerArray?.includes( '7' ) || formData?.zubehoerArray?.includes( 7 )) && (
              <Box flex={1} mr={"0.5rem"}><NumberInput source="anzahlTankkarten" label={"Anzahl Tankkarten"} min={0} max={5} helperText={false} fullWidth/></Box>
            )}
            {(formData?.zubehoerArray?.includes( '33' ) || formData?.zubehoerArray?.includes( 33 )) && (
              <Box flex={1}><NumberInput source="anzahlLadekabel" label={"Anzahl Ladekabel"} min={0} max={5} helperText={false} fullWidth /></Box>
            )}
          </Box>
        )}
      </FormDataConsumer>
    </Box>
  </Card>
}
