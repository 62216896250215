import * as React from "react";
import { useState } from "react";
import { Box, Card } from "@material-ui/core";
import { BooleanInput, FormWithRedirect, SaveButton, useDataProvider, useNotify, useRedirect, useRefresh } from "react-admin";
import GlobalLoading from "../../utils/components/GlobalLoading";
import { makeStyles } from "@material-ui/core/styles";
import BaseDataCard from "./cards/BaseData";
import AdditionalDataCard from "./cards/AdditionalData";
import PickupAddressCard from "./cards/PickupAddress";
import DeliveryAddressCard from "./cards/DeliveryAddress";
import CustomDataCard from "./cards/CustomData";
import { unset } from "lodash";
import { ParagraphBox } from "../../utils/components/FormElements";

export const EntityEdit = ( props ) => {
  const redirect = useRedirect();
  const [loading, setLoading] = useState( false )
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [key, setKey] = useState(Math.random());



  const save = async ( values ) => {
    setLoading( true )
    if( !values.customData?.length ) {
      notify( "Es muss mindestens ein Auftrag angegeben werden", { type: "warning"} )
      setLoading( false )
      return
    }

    const requiredFields = ["pickupDate", "pickupTimeFrom", "pickupTimeTo", "deliveryDate", "deliveryTimeFrom", "deliveryTimeTo"]
    let defaultValues = JSON.parse(JSON.stringify(values))
    unset(defaultValues, "customData")
    let locationSaved = false

    for( let element of values.customData ) {
      if( requiredFields.some( field => !element[field] ) ) {
        notify( "Es fehlen Pflichtfelder in den Aufträgen", { type: "warning"} )
        setLoading( false )
        return
      }

      element.pickupDateRaw = element.pickupDate
      element.deliveryDateRaw = element.deliveryDate
      element.licensePlate = element.licensePlate ? element.licensePlate : values.licensePlate
      element.leasingvertragsnummer = element.leasingvertragsnummer ? element.leasingvertragsnummer : values.leasingvertragsnummer
      element.verzoegerteAbgabe = element.verzoegerteAbgabe ? element.verzoegerteAbgabe : values.verzoegerteAbgabe
      if( element.abholer ) {
        element.driverChangedWithMail = true
      }

      let data = {
        ...defaultValues,
        ...element,
      }

      if( locationSaved ) {
        data.pickupLocation.saveLocation = false
        data.deliveryLocation.saveLocation = false
      }


      await dataProvider.create( "contracts", {
        data: data
      } ).then( (response) => {
        locationSaved = true
        // defaultValues.pickupAddress.saveLocation = false
        // defaultValues.deliveryAddress.saveLocation = false
        notify( `Auftrag ${response.data.auftragsnummer} erstellt`, { type: "success"} )
        setLoading( false )
        setKey(Math.random())
      } ).catch( ( error ) => {
        notify( "Fehler beim Anlegen eines Auftrags: " + error , { type: "error"} )
        setLoading( false )
      } )
    }
  }

  const useStyles = makeStyles(theme => ( {
    card: {
      margin: "0.5rem",
      padding: "1rem"
    },
    cardFullWidth: {
      margin: "0.5rem",
      padding: "1rem",
      overflow: "visible",
      gridColumn: "span 2",
      [theme.breakpoints.down('lg')]: {
        gridColumn: "span 1",
      },
    },
    cardActionButtons: {
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "1rem",
      alignSelf: "center",
      margin: "0.5rem",
      width: "fit-content",
    },
    formContainer: {
      display: "grid",
      position: "relative",
      height: "100%",
      gridTemplateColumns: "repeat(2, 1fr)",
      gap: theme.spacing(2),
      [theme.breakpoints.down('lg')]: {
        gridTemplateColumns: "repeat(1, 1fr)",
      },
    }
  }))

  const classes = useStyles();

  return <FormWithRedirect
    resource={"users"}
    key={key}
    save={save}
    render={( { handleSubmitWithRedirect, pristine, saving } ) => {
      return <form className={classes.form}>
        {loading &&<GlobalLoading/>}
        <Box className={classes.formContainer}>
          <BaseDataCard classes={classes} />
          <AdditionalDataCard classes={classes} />
          <PickupAddressCard classes={classes} />
          <DeliveryAddressCard classes={classes} />
          <CustomDataCard classes={classes} />
          <Card className={classes.cardActionButtons}>
            <SaveButton
              handleSubmitWithRedirect={ handleSubmitWithRedirect }
              pristine={ pristine }
              saving={ saving }
              label="Aufträge erstellen" />
          </Card>
        </Box>

      </form>
    }
    }/>
}
