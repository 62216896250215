import React, { useState } from "react";
import { Confirm, FormDataConsumer, FormWithRedirect, SaveButton, SaveContextProvider, useDataProvider, useNotify, useRedirect, useRefresh } from "react-admin";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { BooleanInput } from "../../utils/components";
import { useRecordContext } from "ra-core";

const stripFollowUpContract = ( formData, createBundleWith = null ) => {
  const stripped = {
    ...formData,
    createBundleWithContract: createBundleWith,
  }
  // delete stripped.followUpContract
  // delete stripped.precedingContract
  return stripped
}

export const CustomSaveButtonWithAdvancedRetreivalCheckButton = props => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();

  const handleSave = (formData) => {
    setLoading(true)

    if( props.isCreate ) {
      dataProvider.create('contracts', {
        data: {
          ...formData,
          createBundleWithContract: props.createBundleWithContract ?? null,
        }
      }).then((response) => {
        // success side effects go here
        notify("Auftrag erfolgreich erstellt", { type: "success" });
        setOpen(false);
        setLoading(false);
        redirect('edit', `/contracts`, response.data.id)
      }).catch((error) => {
        setOpen(false);
        setLoading(false);
        notify(`Fehler beim erstellen: ${error.message}`, { type: "warning" });
      })
    } else {
      const strippedFormData = stripFollowUpContract( formData )
      dataProvider.update('contracts', {
        id: strippedFormData['@id'],
        data: { ...strippedFormData }
      }).then((response) => {
        // success side effects go here
        notify("Auftrag erfolgreich bearbeitet", { type: "success" });
        setOpen(false);
        setLoading(false);
        if( props.redirect ) {
          redirect( 'list', '/contracts' )
        }
      }).catch((error) => {
        // failure side effects go here
        notify(`Fehler beim bearbeiten: ${error.message}`, { type: "warning" });
        setOpen(false);
        setLoading(false)
      })
    }
  }

  return <>
    <FormDataConsumer>
      {({ formData }) => {
        const showWarning = formData.vorholung && formData.pickupDate === formData.deliveryDate
        return showWarning ? <>
          <Confirm
            title="Auftrag speichern"
            content="Vorholung ist noch aktiv obwohl Abholung und Zustellung am selben Tag stattfinden. Sind Sie sicher, dass Sie den Auftrag speichern möchten?"
            onConfirm={() => handleSave(formData)}
            onClose={() => setOpen(false)}
            isOpen={open}
            loading={loading}
          />
          <Button
            onClick={() => setOpen(true)}
            variant="contained"
            color="primary"
            {...props}
          />
        </> : <ContractIsPartOfBundleDialog {...props} formData={formData} />
      }}

    </FormDataConsumer>
  </>
}

export const ContractIsPartOfBundleDialog = ({ formData, ...props }) => {
  const [open, setOpen] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const record = useRecordContext()

  const driverChanged = formData?.hasOwnProperty('driver') && formData?.driver?.userId !== record.driver?.userId
  const showWarning = !props.create && formData.isPartOfBundle && "@id" in formData && (formData?.isStorniert || driverChanged)

  const handleConfirm = async ( data ) => {
    const strippedFormData = stripFollowUpContract( formData )
    if( data.deactivateBundle ) {
      for( const bundle of strippedFormData.bundles ) {
        await dataProvider.update( 'bundles', {
          id: bundle[ '@id' ],
          data: {
            active: false,
          }
        } ).then( ( response ) => {
          // success side effects go here
          notify( `Fahrtpaket ${bundle.bundle} erfolgreich aufgelöst`, { type: "success" } );
        } ).catch( ( error ) => {
          // failure side effects go here
          notify( `Fehler beim bearbeiten: ${error.message}`, { type: "warning" } );
        } )
      }
    }

    dataProvider.update('contracts', {
      id: strippedFormData['@id'],
      data: {
        ...strippedFormData,
      }
    }).then((response) => {
      // success side effects go here
      notify("Auftrag erfolgreich bearbeitet", { type: "success" });
      setOpen(false);
      if( props.redirect ) {
        redirect( 'list', '/contracts' )
      } else {
        refresh()
      }
    }).catch((error) => {
      // failure side effects go here
      notify(`Fehler beim bearbeiten: ${error.message}`, { type: "warning" });
      setOpen(false);
    })
  }

  return <>
    <Dialog fullWidth open={ open } onClose={ () => setOpen(false) }>
      <FormWithRedirect
        {...props}
        save={ handleConfirm }

        render={() => (
          <form>
              <DialogTitle>Auftrag ist Teil eines Fahrtpakets</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Dieser Auftrag ist Teil eines Fahrtpaketes. Wenn Sie diesen Auftrag stornieren oder an einen Fahrer vergeben, kann das Fahrtpaket aufgelöst werden:
                  <br />
                  <br />
                  <BooleanInput source="deactivateBundle" label="Fahrtpaket auflösen" initialValue={true}/>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={ () => setOpen( false ) } color="primary">
                  Abbrechen
                </Button>
                <FormDataConsumer>
                  {({ formData }) => {
                    return <Button onClick={ () => handleConfirm( formData ) } color="primary" variant={"contained"} autoFocus label={"Speichern"}>Speichern</Button>
                  }}
                </FormDataConsumer>
              </DialogActions>
          </form>
        )} />
    </Dialog>
    {showWarning ?
     <Button color={"primary"} variant={"contained"} {...props} onClick={ () => setOpen( true ) } /> :
     <SaveButton {...props} transform={(data) => stripFollowUpContract( data, props.createBundleWithContract )}/>}
  </>
}
